import { FC, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Tabs, Tooltip } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useMainRouterParams } from "routing/authorizedRouter";

import { Icon } from "elements/icon/icon";
import { global } from "models/global";

import { COMPARE, GENERAL, MANAGEMENT, Page, ProjectFolder, REPORTS, SCENARIO } from "../../assets/routing";

import cn from "./modeSwitch.module.less";

const useCurrentPath = (): [ProjectFolder, Page | undefined] => {
  const { scenario } = useMainRouterParams();
  const { pathname } = useLocation();
  let expectedKey = pathname.split("/")[2 + (scenario ? 1 : 0)];

  const folder =
    GROUPS.flat().find(
      (item) =>
        // если ключ группы совпадает
        item.key === expectedKey ||
        // или если среди детей есть соответствующий ключ
        ("children" in item && item.children.find(({ key }) => key === expectedKey))
    ) ??
    // иначе берём дефолтную
    GENERAL;

  const child = "children" in folder ? folder.children.find(({ key }) => key === expectedKey) : undefined;

  return [folder, child];
};

const MenuNarrowButton: FC = observer(() => {
  const [folder] = useCurrentPath();

  return (
    <div className={cn.menuToggler}>
      <Tooltip placement="right" title="Свернуть/показать подменю">
        <Button
          disabled={!("children" in folder) && folder.key !== "infrastructure"}
          onClick={global.toggleNarrowMenu}
          type="text"
          size="large"
          icon={global.isNarrowMenu ? <RightCircleOutlined /> : <LeftCircleOutlined />}
        />
      </Tooltip>
    </div>
  );
});

MenuNarrowButton.displayName = "narrowMenu";

const GROUPS = [[GENERAL, MANAGEMENT], SCENARIO, [COMPARE, REPORTS]];

const isEndOfGroup = (key: string): boolean => GROUPS.find((group) => group.at(-1)!.key === key) !== undefined;

const MenuIcon: FC<{ index: string; title: React.ReactNode; icon: React.ReactNode }> = ({ title, icon, index }) => (
  <Tooltip className={classNames(isEndOfGroup(index) && cn.lastOfGroup)} placement="right" title={title}>
    <span>
      <Icon content={icon} />
    </span>
  </Tooltip>
);

const createSubmenu = (children: { key: string; title: string }[], onNavigate: (key: string) => void): MenuProps["items"] =>
  children.map(({ key, title }) => ({
    key,
    label: (
      <span
        onClick={(e) => {
          e.stopPropagation();
          onNavigate(key);
        }}
      >
        {title}
      </span>
    ),
  }));

const useOnNavigate = () => {
  const navigate = useNavigate();
  const { project, scenario } = useMainRouterParams();

  return useCallback(
    (key: string) => {
      navigate(["", project, scenario, key].filter((v) => v !== undefined).join("/"));
    },
    [navigate, project, scenario]
  );
};

const ModeSwitch: FC<{ className?: string }> = observer(({ className }) => {
  const [folder, child] = useCurrentPath();
  const onNavigate = useOnNavigate();

  const initialKey = child?.key ?? folder.key;
  const [selectedKeys, setSelectedKeys] = useState<string[]>([initialKey]);

  useEffect(() => {
    setSelectedKeys([initialKey]);
  }, [initialKey]);

  const TabsWithSubMenu = GROUPS.flat().map((group, ...content) => ({
    key: group.key,
    label:
      "children" in group && group.children && global.isNarrowMenu ? (
        <Dropdown
          menu={{
            items: createSubmenu(group.children, onNavigate),
            selectable: true,
            selectedKeys,
          }}
          overlayClassName={cn.dropdown}
        >
          <span
            onClick={(e) => {
              e.preventDefault();
              onNavigate(group.key);
            }}
          >
            <Icon content={group.icon} />
          </span>
        </Dropdown>
      ) : (
        <MenuIcon index={group.key} title={group.title} icon={group.icon} />
      ),
    disabled: "element" in content && content.element === null,
  }));

  return (
    <>
      <MenuNarrowButton />
      <Tabs activeKey={folder.key} className={classNames(cn.tabs, className)} onTabClick={onNavigate} tabPosition="left" items={TabsWithSubMenu} />
    </>
  );
});

export { ModeSwitch, useCurrentPath };
