import { FC } from "react";
import { Widget } from "@okopok/components/Table";
import { Button } from "antd";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { FullScreenLoader } from "elements/fullScreen/fullScreen";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { ProducingObject } from "models/project/producingObject/producingObject";
import { useProject } from "models/project/project";

import { useMaterialBalanceContext } from "./materialBalanceProvider";

import cn from "./calculationResult.module.less";

const useColumns = (producingObject: ProducingObject[]): Column[] => [
  {
    title: "Параметр",
    width: { min: 300, max: 330, competitiveness: 1 },
    dataKey: "title",
    type: "string",
  },
  {
    title: "Ед. измерения",
    width: { min: 104, max: 104, competitiveness: 1 },
    dataKey: "measure",
    type: "string",
  },
  //@ts-ignore
  ...producingObject.map(({ title, id }) => ({
    title,
    width: { min: 72, max: 144, competitiveness: 1 },
    dataKey: id,
    type: "number",
    editable: true,
  })),
];
const InputData: FC = observer(() => {
  const { producingObjects } = useProject()!;
  const columns = useColumns(Array.from(producingObjects.values ?? []));
  const materialBalance = useMaterialBalanceContext();

  if (materialBalance.isLoadingSettings) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <PageFrameTitlePortal onSave={materialBalance.saveSettings}>
        <Button type="primary" onClick={materialBalance.calculate} loading={materialBalance.isCalculating}>
          Расчитать
        </Button>
      </PageFrameTitlePortal>
      <SimpleTableContext
        data={materialBalance.store}
        columns={columns}
        exportFileName="material-balance-settings"
        tableOptions={{
          onRow: ({ expand }) => ({
            className: expand === undefined ? cn.tableRowPlain : cn.tableRowSecondary,
          }),
        }}
      >
        <Widget />
      </SimpleTableContext>
    </>
  );
});

export { InputData };
