import { FC, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useMainRouterParams } from "routing/authorizedRouter";

import { FullScreenEmpty } from "elements/fullScreen/fullScreen";
import { Preloader } from "features/preloader/preloader";
import { global } from "models/global";
import { useProject } from "models/project/project";

import { ProjectFolder } from "./authorized/assets/routing";
import { useCurrentPath } from "./authorized/modesMenu/modeSwitch/modeSwitch";
import { usePagePostfix } from "./secondaryMenu/scenarioMenu";
import { SecondaryMenu } from "./secondaryMenu/secondaryMenu";
import { TreeMenu } from "./treeMenu/treeMenu";
import { ProjectContextProvider } from "./projectContextProvider";

import cn from "./projectOutline.module.less";

const needWellTreePages: string[][] = [
  ["reports", "report-oil-state-plan"],
  ["reports", "report-apg-state-plan"],
  ["wells", "summary"],
  ["wells", "injection"],
  ["wells", "injection", "params"],
  ["wells", "prediction"],
  ["wells", "prediction", "results"],
];

const checkNeedWellTree =
  ({ key }: ProjectFolder, pagePostfix: string[]) =>
  (record: string[]) => {
    return [key, ...pagePostfix].join() === record.join();
  };

const isPageNeedWellTree = (folder: ProjectFolder, pagePostfix: string[]) => {
  return needWellTreePages.some(checkNeedWellTree(folder, pagePostfix));
};

const ProjectOutline: FC = observer(() => {
  const project = useProject();
  const params = useParams();
  const paramsParsed = useMainRouterParams();
  const pagePostfix = usePagePostfix();
  const [folder] = useCurrentPath();

  const isNeedWellTree = isPageNeedWellTree(folder, pagePostfix);

  useEffect(() => {
    if (project?.id) {
      global.logger.setProject(project);
    }
  }, [project]);

  if (project === null) {
    if (paramsParsed.project === undefined) {
      return <FullScreenEmpty>Страница {params.project} не найдена</FullScreenEmpty>;
    }
    return <FullScreenEmpty>Не удалось найти проект с ключом {params.project}</FullScreenEmpty>;
  }
  return (
    <Preloader hooks={[useProject]}>
      <ProjectContextProvider>
        <SecondaryMenu />
        {isNeedWellTree && <TreeMenu />}
        <div className={cn.main}>
          <Outlet />
        </div>
      </ProjectContextProvider>
    </Preloader>
  );
});

export { ProjectOutline };
