import { computed, makeObservable, observable, ObservableSet, reaction, runInAction, transaction } from "mobx";
import objectHash from "object-hash";
import { debounce } from "throttle-debounce";

import {
  type Analogs as AnalogsType,
  type AnalogsRequest,
  AnalogsResult,
  Fitting,
  getAnalogs,
  itemKey,
  RATE_FITTED,
} from "services/back/techForecast/request";
import { getRandomUid } from "utils/random";

import { ScalarDump } from "./methods";
import { DEBOUNCE_TIMEOUT, type WellTechForecast } from "./wellTechForecast";

class Analogs {
  isApplyConstruction: boolean = true;
  prodMonthDuration: number | null = 12;
  oilRatio: number | null = null;
  oilRatioDiv: number | null = null;
  liquidRatio: number | null = null;
  liquidRatioDiv: number | null = null;
  hidden: ObservableSet<string> = observable.set();

  readonly uid = getRandomUid();

  analogs = observable.map<string, AnalogsResult | undefined>();
  currentAnalogsСrutch: AnalogsResult | undefined;

  constructor(readonly mode: "oil" | "liquid", private readonly fc: WellTechForecast) {
    makeObservable(this, {
      isApplyConstruction: observable,
      prodMonthDuration: observable,
      oilRatio: observable,
      oilRatioDiv: observable,
      liquidRatio: observable,
      liquidRatioDiv: observable,

      isValid: computed,
      isLoading: computed,
      save: computed,
      dump: computed,
      currentAnalogs: computed,
      a: computed,
      fitting: computed,
      selectedAmount: computed,
      amount: computed,
      analogsRequest: computed,
    });

    reaction(
      () => this.analogsRequest,
      () => {
        this.#request();
      }
    );
    this.#request();
  }

  #requestImmediately = () => {
    const { analogsRequest } = this;
    const hash = objectHash(analogsRequest);
    if (this.analogs.has(hash)) {
      return;
    }
    runInAction(() => {
      this.analogs.set(hash, undefined);
    });
    getAnalogs(analogsRequest).then((result) => {
      runInAction(() => {
        try {
          this.analogs.set(hash, result);
        } catch {}
      });
    });
  };

  readonly #request = debounce(DEBOUNCE_TIMEOUT, this.#requestImmediately, { atBegin: false });

  readonly applyModal = (
    hidden: ObservableSet<string>,
    fittings: Pick<AnalogsResult, "liquidRateM3Fitted" | "oilRateTFitted">
  ) =>
    runInAction(() =>
      transaction(() => {
        const currentHash = objectHash(this.analogsRequest);
        this.hidden.replace(hidden);
        const newHash = objectHash(this.analogsRequest);
        const current = this.analogs.get(currentHash)!;
        current.liquidRateM3Fitted = fittings.liquidRateM3Fitted;
        current.oilRateTFitted = fittings.oilRateTFitted;
        this.analogs.clear();
        this.analogs.set(newHash, current);
        this.currentAnalogsСrutch = current;
      })
    );

  holder(field: "prodMonthDuration" | "oilRatio" | "oilRatioDiv" | "liquidRatio" | "liquidRatioDiv") {
    return (v: number | null) =>
      runInAction(() => {
        this[field] = v;
        this.#request();
      });
  }

  readonly applyConstructionHolder = (v: boolean) =>
    runInAction(() => {
      this.isApplyConstruction = v;
    });

  readonly selectAllHolder = () =>
    runInAction(() => {
      this.hidden.clear();
    });

  get analogsRequest(): AnalogsRequest {
    let wellIds =
      this.hidden.size === 0
        ? null
        : this.currentAnalogsСrutch?.items.filter((v) => !this.hidden.has(itemKey(v))).map((v) => v.wellId) ?? null;
    if (Array.isArray(wellIds) && wellIds.length === this.currentAnalogsСrutch?.items.length) {
      wellIds = null;
    }
    return {
      scenarioId: this.fc.key.scenarioId,
      stratumId: this.fc.key.stratumId,
      prodMonthDuration: this.prodMonthDuration!,
      producingObjectId: this.fc.producingObjectId,
      wellTypeId: this.isApplyConstruction ? this.fc.wellTypeId : null,
      wellIds,
    };
  }

  get dump(): ScalarDump {
    return {
      fnType: "geometric_progression",
      a: this.a ?? null,
    };
  }

  get a(): number | undefined {
    return this.fitting?.a;
  }

  get fitting(): Fitting | undefined {
    return this.currentAnalogs?.[RATE_FITTED[this.mode]];
  }

  get selectedAmount(): number | null {
    let counter = 0;
    const { currentAnalogs } = this;
    if (currentAnalogs === undefined) {
      return null;
    }
    const { hidden } = this;
    if (hidden.size === 0) {
      return currentAnalogs.items.length;
    }
    for (const item of currentAnalogs.items)
      if (hidden.has(itemKey(item))) {
        counter += 1;
      }
    return currentAnalogs.items.length - counter;
  }

  get amount(): number | null {
    return this.currentAnalogs?.items.length ?? null;
  }

  get currentAnalogs() {
    return this.analogs.get(objectHash(this.analogsRequest));
  }

  get isValid(): boolean {
    if ((this.oilRatio === null) !== (this.oilRatioDiv === null)) {
      return false;
    }
    if ((this.liquidRatio === null) !== (this.liquidRatioDiv === null)) {
      return false;
    }
    return this.prodMonthDuration !== null;
  }

  get isLoading(): boolean {
    return this.currentAnalogs === undefined;
  }

  get save(): AnalogsType {
    return {
      isApplyConstruction: this.isApplyConstruction,
      prodMonthDuration: this.prodMonthDuration,
      oilRatio: this.oilRatio,
      oilRatioDiv: this.oilRatioDiv,
      liquidRatio: this.liquidRatio,
      liquidRatioDiv: this.liquidRatioDiv,
      hidden: [...this.hidden.values()],
    };
  }

  fromSave(data: AnalogsType) {
    runInAction(() =>
      transaction(() => {
        this.isApplyConstruction = data.isApplyConstruction;
        this.prodMonthDuration = data.prodMonthDuration;
        this.oilRatio = data.oilRatio;
        this.oilRatioDiv = data.oilRatioDiv;
        this.liquidRatio = data.liquidRatio;
        this.liquidRatioDiv = data.liquidRatioDiv;
        this.hidden.replace(data.hidden);
      })
    );
  }
}

export { Analogs };
