import { type FC } from "react";
import { observer } from "mobx-react-lite";
import { PageFrame, TabItem } from "routing/pageFrame/pageFrame";

import { CalculationResult } from "features/materialBalance/calculationResult";
import { InputData } from "features/materialBalance/inputData";
import { MaterialBalanceContextProvider } from "features/materialBalance/materialBalanceProvider";
import { Preloader } from "features/preloader/preloader";
import { useProject } from "models/project/project";

const TABS: TabItem[] = [
  {
    key: "inputs",
    label: "Входные данные",
    default: true,
    children: <InputData />,
  },
  {
    key: "results",
    label: "Результаты расчета",
    children: <CalculationResult />,
  },
];

const MaterialBalance: FC = observer(() => {
  return (
    <Preloader hooks={[() => useProject()?.producingObjects]}>
      <MaterialBalanceContextProvider>
        <PageFrame title="Материальный баланс" tabsWidthFix={335} tabs={TABS} />
      </MaterialBalanceContextProvider>
    </Preloader>
  );
});

export { MaterialBalance };
